<template>
    <div>
        <div v-if="showFilter" class="div-filter" v-show="schedule.length > 0">
            <b-row>
                <b-col sm="3">
                    <DateTime title="Período" fieldTarget="period" format="DD/MM/YYYY" type="date" :range="true"
                        :confirm="true" confirmText="Filtrar" placeholder :required="false" v-model="period" />
                </b-col>
                <b-col sm="9">
                    <div class="div-btn-update text-right">
                        <Button _key="btnUpdate" title="Atualizar" classIcon="fa-solid fa-arrows-rotate" size="small"
                            type="info" :clicked="getAll" />
                    </div>
                </b-col>
            </b-row>
        </div>
        <ScrollBar :minHeight="400" :maxHeight="400">
            <table class="table table-responsive-xs">
                <thead>
                    <tr>
                        <th>
                            <span class="title-header">Cliente/Lead</span>
                        </th>
                        <th class="text-center">
                            <i class="fa-solid fa-calendars"></i>
                            <span class="title-header">
                                Retorno
                            </span>
                        </th>
                        <th>
                            <span class="title-header"> Res.. </span>
                        </th>
                        <th class="text-center">
                            <span class="title-header">Status</span>
                        </th>
                        <th v-if="showButtons"></th>
                    </tr>
                </thead>
                <tbody v-for="item in schedule">
                    <td :class="{ 'td-description': !customerSupplierId }">
                        <span>{{ item.customerName }}</span>
                        <span class="title-description" v-if="item.description">
                            -
                            {{ item.description }}</span>
                    </td>
                    <td class="text-center">
                        <span>
                            {{ item.dateStr }}
                        </span>
                    </td>
                    <td>
                        <div>
                            <img class="img-current" :src="baseUrl + item.user.photo">
                        </div>
                    </td>
                    <td class="text-center">
                        <span v-if="item.status == 'delayed'">
                            <i class="fa-solid fa-triangle-exclamation icon-delayed"></i>
                            <span> Em atraso</span>
                        </span>
                        <span v-else>
                            <i class="fa-solid fa-timer icon-schedule"></i>
                            <span> Agendada</span>
                        </span>
                    </td>
                    <td class="text-center" v-if="showButtons">
                        <Button :_key="item.id + 'add'" classIcon="fa-solid fa-play" color="white" backGroundColor="#1976D2"
                            size="small" :params="item" :clicked="open" />
                        <Button :_key="item.id + 'cancel'" classIcon="fa-solid fa-ban" type="danger" size="small"
                            :params="item" :clicked="cancel" />
                    </td>
                </tbody>
                <tbody v-show="schedule.length == 0">
                    <tr>
                        <td colspan="12">
                            <span class="title">Nenhum registro encontrado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ScrollBar>
    </div>
</template>
<script>


import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    name: "ScheduleList",
    components: {
        Molded,
        ScrollBar,
        Button,
        DateTime,
    },
    props: {
        channel: Number,
        customerSupplierId: String,
        showScheduleCreate: {
            type: Boolean,
            default: false
        },
        showFilter: {
            type: Boolean,
            default: true
        },
        showButtons: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            baseUrl: "https://espaco.blob.core.windows.net/nixloc-photo-user/",
            urlGetAll: "/api/v1/chat/schedule/get-all",
            urlCancelAllApi: "/api/v1/chat/schedule/delete",
            urlOpen: "/api/v1/chat/conversation/open",
            period: ["", ""],
            loading: true
        }
    },
    computed: {
        ...mapState("chat", ["schedule"]),
        ...mapGetters("generic", ["event"]),
        ...mapGetters("chat", ["usersFilter"]),
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapActions("generic", ["getApi", "postApi", "deleteAllApi"]),
        ...mapMutations("chat", ["addSchedule"]),
        ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
        getAll() {
            let params = {
                url: this.urlGetAll,
                obj: {
                    channel: this.channel,
                    customerSupplierId: this.customerSupplierId,
                    start: this.period[0],
                    end: this.period[1],
                    ids: this.usersFilter
                }
            };
            this.getApi(params).then((response) => {
                this.addSchedule(response.content.data);
                this.loading = false;
                this.removeLoading(["btnUpdate"]);
            });
        },
        open(item) {
            let params = {
                url: this.urlOpen, obj: {
                    channel: this.channel,
                    contactId: item.contact.id,
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "conversationStarted", data: response.content });
                    this.cancel(item);
                    this.hideModal();
                }
                this.removeLoading([item.id + 'add']);
            });
        },
        cancel(item) {
            let params = {
                url: this.urlCancelAllApi,
                selected: [item.id],
            };
            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.getAll();
                }
                this.removeLoading([item.id + 'cancel']);
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "scheduleCreated") {
                    this.getAll();
                }
            },
            deep: true,
        },
        usersFilter: {
            handler() {
                let self = this;
                setTimeout(function () {
                    self.getAll();
                }, 100);
            },
            deep: true,
        },
        period: {
            handler() {
                let self = this;
                setTimeout(function () {
                    self.getAll();
                }, 100);
            },
            deep: true,
        },
    },
}
</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.td-description {
    width: 500px;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.title-description {
    font-size: 13px;
    color: rgb(109, 108, 108);
    font-style: italic;
}

.img-current {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-top: 3px;
}

.icon-delayed {
    color: red;
}

.icon-schedule {
    color: #3D4EAE
}

.div-filter {
    margin-bottom: 10px;
}


.div-btn-update {
    margin-top: 35px;
}
</style>