<template>
    <div>
        <b-row>
            <b-col sm="7">
                <ScrollBar :minHeight="400" :maxHeight="400">
                    <div v-if="params.length == 0">
                        <div v-for="template in templates">
                            <div class="div-template" @click="selectTemplate(template)">
                                <span>
                                    {{ template.elementName }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="div-back text-right" @click="params = []">
                            <i class="fa-solid fa-circle-arrow-left"></i>
                        </div>
                        <div v-for="(param, index) in params" :key="index">
                            <InputText :title="'parâmetro ' + (index + 1)" field="userName" v-model="params[index]" />
                        </div>
                    </div>
                </ScrollBar>
            </b-col>
            <b-col sm="5">
                <div class="container-phone">
                    <div class="div-view">Pré-visualização</div>
                    <div class="div-msg">
                        <span v-if="formattedTemplate">
                            {{ formattedTemplate }}
                        </span>
                        <div class="text-center not-template" v-else>
                            Nenhum template selecionado
                        </div>
                    </div>
                    <div class="text-center">
                        <Button _key="btnSendTemplate" :disabled="template == 'Nenhum template selecionado'"
                            title="Enviar Template" type="primary" classIcon="fa-solid fa-paper-plane-top" size="small"
                            :clicked="sendTemplateHandle" />
                    </div>
                    <br>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

export default {
    name: "Template",
    props: {
        templates: Array,
        sendTemplate: Function
    },
    components: {
        ScrollBar,
        InputText,
        Button
    },
    data() {
        return {
            templateId: "",
            templateStr: "",
            params: [],
        }
    },
    methods: {
        sendTemplateHandle() {
            this.sendTemplate({ id: this.templateId, content: this.formattedTemplate, params: this.params });
        },
        selectTemplate(template) {
            this.templateId = template.id;
            this.templateStr = template.data;
            this.convertParams(template.data);
        },
        convertParams(template) {
            const mensagem = template;
            const regex = /{{(\d+)}}/g;
            let matches = [];
            let match;

            while ((match = regex.exec(mensagem)) !== null) {
                matches.push(`{{${match[1]}}}`);
            }
            this.params = matches;
        },
    },
    computed: {
        formattedTemplate() {
            var template = JSON.stringify(this.templateStr);
            this.params.forEach((param, index) => {
                template = template.replace(`{{${index + 1}}}`, param);
            });

            return template = template.replace(/"/g, '');
        }
    }
}

</script>

<style scoped>
.div-template {
    border: 1px solid #e8eaed;
    background-color: white;
    border-radius: 8px !important;
    padding-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.container-phone {
    min-height: 400px;
    background-color: #e2dbd2;
    border-radius: 15px;
}

.div-view {
    padding: 10px;
}

.div-msg {
    margin: 20px;
    background-color: white;
    min-height: 70px;
    border-radius: 10px;
    padding: 8px;
}

.div-back {
    cursor: pointer;
    font-size: 18px;
}

.not-template {
    color: darkgray;
    font-style: italic;
    font-size: 12px;
}
</style>